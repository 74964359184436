import styled from 'styled-components/macro';
import { rem } from 'polished';
import { lighten, darken } from 'polished';

const darkBlue = darken(0, '#56b2ff');
const lightBlue = lighten(0.25, '#56b2ff');

export const Title = styled.header`
  margin: 0 0 2.8rem;
  color: #434345;
  font-size: ${rem('36px')};
  line-height: 1;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: ${rem('48px')};

    span {
      display: block;

      + span {
        font-size: ${rem('72px')};
      }

      &:only-child {
        font-size: ${rem('60px')};
      }
    }
  }
`;

export const SubTitle = styled.header`
  margin: 0 0 ${rem('32px')};
  /* background-image: linear-gradient(
    0deg,
    ${darkBlue} 0%,
    ${darkBlue} 10%,
    ${lightBlue} 50%,
    ${darkBlue} 90%,
    ${darkBlue} 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
  /* text-shadow: 0 0 ${rem('12px')} rgba(0, 0, 0, 0.35); */
  /* color: #56b2ff; */
  color: #fff;
  font-size: ${rem('32px')};
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: ${rem('3.2px')};

  &:last-child {
    margin-bottom: 0;
  }

  br {
    display: none;
  }

  @media (max-width: 767px) {
    font-size: ${rem('18px')};

    br {
      display: block;
    }
  }
`;

export const Header = styled.header`
  margin: 0 0 ${rem('16px')};
  font-size: ${rem('40px')};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: ${rem('4px')};
  text-align: center;
`;

export const SubHeader = styled.header`
  margin: ${rem('56px')} 0 ${rem('32px')};
  font-size: ${rem('24px')};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: ${rem('2.8px')};
  text-align: center;

  @media (max-width: 767px) {
    margin: ${rem('48px')} 0 ${rem('18px')};
  }
`;

export const Text = styled.div`
  width: 80vw;
  font-size: ${rem('22px')};
  font-weight: 400;
  text-align: center;

  @media (max-width: 767px) {
    font-size: ${rem('16px')};

    br {
      display: none;
    }
  }
`;
