import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.scss';
import { menu } from '__mocks__/menu';

export const Nav = ({ clickAction, ...props }) => {
  return (
    <div className="main-menu" {...props}>
      <ul className="menu">
        {menu.map((item) => (
          <li className="menu__item" onClick={clickAction}>
            <NavLink className="menu__link" to={item.url}>
              {item.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
