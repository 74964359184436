import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.scss';
import { menu } from '__mocks__/menu';

export const NavMain = ({ ...props }) => {
  return (
    <div className="menu-start" {...props}>
      <ul className="menu-start__list">
        {menu.map((item) => (
          <li key={item.url} className="menu-start__item">
            <NavLink className="menu-start__link" to={item.url}>
              {item.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
