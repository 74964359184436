import React, {
  useState,
  useContext,
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import { useCombinedRefs } from 'hooks';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

import { CSSTransition } from 'react-transition-group';
import { useRouteMatch } from 'react-router-dom';

import { WheelContext } from 'contexts';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';
import { css } from 'styled-components/macro';
import { rem } from 'polished';
import './style.scss';

const defaultStyles = css`
  // overflow: hidden;
  // opacity: 0;
`;

const enteredStyles = css`
  // opacity: 1;
`;

const defaultReloadStyles = css`
  // opacity: 0;
`;

const contentPageStyles = css`
  justify-content: flex-start;
  padding-top: ${rem('120px')};
  padding-bottom: ${rem('96px')};
`;

const scrollbar = css`
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar {
    width: ${rem('6px')};
    border-radius: ${rem('3px')};
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${rem('3px')};
    background: #56b2ff;
  }
`;

const pageStyles = css`
  ${({ phase, phaseReload }) => {
    if (phaseReload === 'entered' || !phaseReload) {
      return phase === 'entered' ? enteredStyles : defaultStyles;
    }

    return defaultReloadStyles;
  }};
  ${!isMobile ? scrollbar : null};
`;

export const Page = styled.section`
  ${pageStyles};
`;

export const ContentPage = styled.section`
  ${pageStyles};
  ${contentPageStyles};
`;

export const ScrollPage = forwardRef(
  ({ toUp, toDown, children, bottomNavRef, ...props }, forwardedRef) => {
    const {
      lastWheelTime,
      setNextPage,
      setIsScrollMode: setIsGlobalScrollMode,
    } = useContext(WheelContext);
    const innerRef = useRef();
    const combinedRef = useCombinedRefs(forwardedRef, innerRef);
    const [isWheeledUp, setIsWheeledUp] = useState(false);
    const [isWheeledDown, setIsWheeledDown] = useState(false);
    const [isScrollMode, setIsScrollMode] = useState(true);
    // const [showBottomNav, setShowBottomNav] useSta
    const match = useRouteMatch();
    console.log(bottomNavRef);

    useEffect(() => {
      if (innerRef && props.phase === 'entered') {
        const el = innerRef.current;
        const isScrollMode = el && el.scrollHeight <= el.clientHeight;
        setIsScrollMode(isScrollMode);
        setIsGlobalScrollMode(isScrollMode);
        setNextPage(toDown);
      }
    }, [props.phase, setIsGlobalScrollMode, setNextPage, toDown]);
    const sAdditionalClassName = children?.props?.className
      ? ` page_${children?.props?.className}`
      : '';

    const sClassName = 'page' + sAdditionalClassName;

    return (
      <Page
        className={sClassName}
        {...props}
        isScrollMode={isScrollMode}
        ref={combinedRef}
        onWheel={(e) => {
          const el = e.currentTarget;
          const now = Date.now();

          if (now - lastWheelTime.current < 500) {
            console.log('lastWheelTime');
            return true;
          }
          lastWheelTime.current = now;

          if (
            e.deltaY > 0 &&
            !isWheeledDown &&
            !isWheeledUp &&
            Boolean(toDown) &&
            el.scrollHeight - el.scrollTop === el.clientHeight
          ) {
            setIsWheeledDown(true);
          } else if (
            e.deltaY < 0 &&
            !isWheeledUp &&
            !isWheeledDown &&
            Boolean(toUp) &&
            !el.scrollTop
          ) {
            setIsWheeledUp(true);
          }
        }}
      >
        {children}
        {toUp && isWheeledUp && <Redirect to={toUp} />}
        {toDown && isWheeledDown && <Redirect to={toDown} />}
        {isMobile && (
          <CSSTransition
            timeout={300}
            classNames="bottomnav"
            appear={bottomNavRef.current.animate}
            in={true}
          >
            <div className="bottomnav">
              <div className="bottomnav__container">
                {toUp && (
                  <Link
                    to={toUp}
                    className="bottomnav__btn bottomnav__btn--up"
                  ></Link>
                )}

                {toDown && (
                  <Link
                    to={toDown}
                    className="bottomnav__btn bottomnav__btn--down"
                  ></Link>
                )}
              </div>
            </div>
          </CSSTransition>
        )}
      </Page>
    );
  }
);
