import React, { useState, forwardRef } from 'react';
import { useApi } from 'hooks';
import classNames from 'classnames';
import { Page, Title } from 'components';
import { ReactComponent as ExpandArrow} from './img/arrow.svg';
import { isMobile } from 'react-device-detect';
import { vacancies } from '__mocks__/vacancies';
import { Tags } from 'components';
import './style.scss';

export const Vacancies = forwardRef((props, forwardedRef) => {
  const [activeId, setActiveId] = useState({});
  const toggleActiveId = (id) => {
    // const newActiveId = Object.assign({}, activeId);
    // activeId[id] = !activeId[id];
    setActiveId({...activeId,[id]: !activeId[id]});
  }
  return (
    <Page
      className="page"
      {...props}
      ref={forwardedRef}
    >
      <div className="vacancies-page">
        <div className="vacancies-page__container">
          <h1 className="vacancies-page__title">Вакансии</h1>

          <div className="vacancies-page__wrapper">
            {vacancies.map((vacancy) => (
              <div key={vacancy.id} className={classNames("vacancy", {
                'vacancy_active': !!activeId[vacancy.id],
                ['vacancy_' + vacancy.style]: !!vacancy.style,
              })}>
                <h2 className='vacancy__title' onClick={() => toggleActiveId(vacancy.id)}>
                  {vacancy.title}
                  <div className='vacancy__arrow'>
                    <ExpandArrow />
                  </div>
                </h2>
                <Tags className='vacancy__tags' tags={vacancy.tags} />
                <div className='vacancy__content-wrapper'>
                  <div className='vacancy__content' dangerouslySetInnerHTML={{ __html: vacancy.html }}></div>
                  <button type='button' className='vacancy__button'>откликнуться</button>
                </div>
                
              </div>
            ))}

          </div>
        </div>
      </div>
    </Page>
  );
});