import styled from 'styled-components/macro';
import { rem } from 'polished';
import { Nav } from '.';

export const Waypoints = styled(Nav)`
  position: fixed;
  z-index: 5;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: ${rem('60px')};
  counter-reset: page;

  a {
    counter-increment: page;

    span {
      display: none;
    }

    &:hover {
      background: transparent;
      opacity: 1;
      color: #78d5ff;

      span {
        transform: translateX(-100%);
      }
    }

    &:nth-child(1) {
      &.active ~ a:last-child::after {
        transform: translateY(0);
        opacity: 1;
      }
    }

    &:nth-child(2) {
      &.active ~ a:last-child::after {
        transform: translateY(${rem(`${72 * 1 + 30 * 1}px`)});
        opacity: 1;
      }
    }

    &:nth-child(3) {
      &.active ~ a:last-child::after {
        transform: translateY(${rem(`${72 * 2 + 30 * 2}px`)});
        opacity: 1;
      }
    }

    &:nth-child(4) {
      &.active ~ a:last-child::after {
        transform: translateY(${rem(`${72 * 3 + 30 * 3}px`)});
        opacity: 1;
      }
    }

    &:nth-child(5) {
      margin-bottom: 0;

      &.active::after {
        opacity: 1;
      }
    }

    &:last-child {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 2px;
        height: ${rem('30px')};
        background: #56b2ff;
        transition: transform 0.3s ease;
        transform: translateY(${rem(`${72 * 4 + 30 * 4}px`)});
        opacity: 0;
      }
    }

    &::before {
      content: '0' counter(page);
      display: block;
      font-size: ${rem('16px')};
    }
  }

  @media (max-width: 767px) {
    width: ${rem('48px')};
  }
`;
