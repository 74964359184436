import React, { forwardRef } from 'react';
import { useApi } from 'hooks';
import { ScrollPage, NavMain } from 'components';
import './style.scss';
import logo from './img/main_logo.svg';

export const Main = forwardRef((props, forwardedRef) => {
  return (
    <ScrollPage ref={forwardedRef} toDown="/services" {...props}>
      <div className="homepage">
        <div className="homepage__left">
          <div className="homepage__logo">
            <img alt="Rubium logo" src={logo} />
          </div>
          <div className="homepage__text">
            <h1 className="title">Rubium</h1>
            <p className="tagline">
              Разработка сайтов
              <br /> и сложных интернет-решений
            </p>
          </div>
        </div>
        <div className="homepage__right">
          <NavMain />
        </div>
      </div>
    </ScrollPage>
  );
});
