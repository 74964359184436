import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

const backendOptions = {
  loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}.json`,
};

i18n
  // .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: backendOptions,
    lng: 'ru',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
