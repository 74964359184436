export const menu = [
  {
    title: 'Услуги',
    url: '/services',
  },
  {
    title: 'Проекты',
    url: '/portfolio',
  },
  // {
  //   title: 'Разработки',
  //   url: '/',
  // },
  {
    title: 'О нас',
    url: '/about',
  },
  {
    title: 'Связаться с нами',
    url: '/contacts',
  },
];
