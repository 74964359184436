import {
  About,
  Contacts,
  Main,
  Portfolio,
  Services,
  Vacancies,
  PortfolioDetail,
} from 'routes';

export const routes = [
  { path: '/', Component: Main },
  { path: '/about', Component: About },
  { path: '/contacts', Component: Contacts },
  { path: '/portfolio', Component: Portfolio },
  {
    path: '/portfolio/:slug',
    Component: PortfolioDetail,
  },
  { path: '/services', Component: Services },
  { path: '/vacancies', Component: Vacancies },
];
