import React, { forwardRef } from 'react';
import styled from 'styled-components/macro';
import './style.scss';

import { ScrollPage, Text, Title, SubHeader, Tech } from 'components';

const StyledSubHeader = styled(SubHeader)`
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;

export const About = forwardRef((props, forwardedRef) => {
  return (
    <ScrollPage
      ref={forwardedRef}
      toUp="/portfolio"
      toDown="/contacts"
      {...props}
    >
      <div className="about-page">
        <div className="about-page__left">
          <h1 className="about-page__title">Стек технологий</h1>
          <p className="about-page__description">
            Мы постоянно развиваемся, изучаем сорвеменные технологии и новые
            битлиотеки и фреймворки. Наш инструментарий не органичивается
            конкретным перечнём названий под каждый проект мы разрабатываем
            архитектуру и внедряем технологии, исходя из задач и требований
            самого проекта
          </p>
        </div>

        <Tech />
      </div>
    </ScrollPage>
  );
});
