import React, { useState, forwardRef } from 'react';
import { NavLink } from 'react-router-dom';

import { useApi } from 'hooks';
import { Marquee } from 'components/Marquee';
import { ScrollPage } from 'components';

import { projects } from '__mocks__/portfolio';
import './style.scss';
export const Portfolio = forwardRef((props, forwardedRef) => {
  const endpoint = 'projects';

  const [{ done, isLoading, isError, data }, setUrl] = useApi(endpoint, []);

  const slidesLeft = Array(4)
    .fill({ img: '/images/portfolio/' })
    .map((item, i) => ({ ...item, img: item.img + `slide-${i + 1}.png` }));
  const slidesRight = slidesLeft.reverse();
  const detailId = data.items?.[0].id || '';

  return (
    <ScrollPage
      ref={forwardedRef}
      toUp="/services"
      toDown="/about"
      className="page page_portfolio"
      {...props}
    >
      <div className="portfolio-page">
        <div className="portfolio-page__left">
          <h1 className="portfolio-page__title">Реализованные проекты</h1>
          <img
            className="portfolio-page__hero"
            src="/images/portfolio/hero.png"
            alt=""
          />
          <div className="portfolio-page__description-wrap">
            <div className="portfolio-page__description">
              <p>Мы имеем большой опыт в разработке:</p>
              <ul>
                <li>e-commerce проектов</li>
                <li>высокконагруженных порталов</li>
                <li>промо-сайтов с анимацией и интерактивом</li>
                <li>MVP для стартапов с нестандартной архитектурой</li>
              </ul>
            </div>
            <NavLink
              to={`/portfolio/${detailId}`}
              className="portfolio-page__detail"
            >
              <span>Смотреть все</span>
              <svg
                width="23"
                height="16"
                viewBox="0 0 23 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 8.38184C0 8.16085 0.0865376 7.94892 0.240575 7.79266C0.394613 7.63641 0.603534 7.54862 0.821376 7.54862H20.1944L15.0246 2.30602C14.8704 2.14957 14.7837 1.93737 14.7837 1.7161C14.7837 1.49484 14.8704 1.28264 15.0246 1.12619C15.1788 0.969732 15.388 0.881836 15.6061 0.881836C15.8243 0.881836 16.0334 0.969732 16.1877 1.12619L22.7587 7.79192C22.8352 7.86932 22.8959 7.96126 22.9373 8.06249C22.9787 8.16372 23 8.27224 23 8.38184C23 8.49143 22.9787 8.59995 22.9373 8.70118C22.8959 8.80241 22.8352 8.89435 22.7587 8.97175L16.1877 15.6375C16.0334 15.7939 15.8243 15.8818 15.6061 15.8818C15.388 15.8818 15.1788 15.7939 15.0246 15.6375C14.8704 15.481 14.7837 15.2688 14.7837 15.0476C14.7837 14.8263 14.8704 14.6141 15.0246 14.4577L20.1944 9.21505H0.821376C0.603534 9.21505 0.394613 9.12727 0.240575 8.97101C0.0865376 8.81475 0 8.60282 0 8.38184Z"
                  fill="white"
                />
              </svg>
            </NavLink>
          </div>
        </div>
        <div className="portfolio-page__right">
          <Marquee items={slidesLeft} duration={'30s'} />
          <Marquee items={slidesRight} duration={'30s'} reverse={true} />
        </div>
      </div>
    </ScrollPage>
  );
});
