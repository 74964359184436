import { css } from 'styled-components/macro';
import { rem, darken } from 'polished';

export const buttonStyle = css`
  position: relative;
  display: block;
  width: ${rem('300px')};
  margin: ${rem('32px')} 0 0;
  padding: ${rem('12px')};
  font-size: ${rem('20px')};
  font-weight: 400;
  text-transform: uppercase;
  background: #56b2ff;
  border-radius: 6px;
  color: #ffffff;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${darken(0.2, '#56b2ff')};
  }

  &:active {
    top: 1px;
  }

  @media (max-width: 767px) {
    width: ${rem('240px')};
    padding: ${rem('8px')};
    font-size: ${rem('18px')};
  }
`;
