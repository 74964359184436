import React from 'react';
import styled from 'styled-components/macro';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import logo from './img/logo.svg';
import name from './img/name.svg';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
`;

const Image = styled.img`
  &[alt='logo'] {
    width: ${rem('46px')};
  }

  &[alt='name'] {
    width: ${rem('124px')};
    margin: 0 0 0 ${rem('20px')};
  }

  @media (max-width: 767px) {
    &[alt='name'] {
      display: none;
    }
  }
`;

export const Logo = (props) => {
  return (
    <StyledLink to="/" {...props}>
      <Image src={logo} alt="logo" />
      <Image src={name} alt="name" />
    </StyledLink>
  );
};
