import styled from 'styled-components/macro';
import { rem } from 'polished';
export const Button = styled('button')`
  border: 1px solid #000;
  padding: 10px 20px;
  margin: 30px auto;
  font-size: ${rem('24px')};
  position: relative;
  display: block;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    right: 0;
    bottom: 0;
    width: 150%;
    border-right: 20px solid transparent;
    border-bottom: 40px solid #000;
    transform: translateX(-100%);
    transition: 0.4s;
    z-index: -1;
  }

  &:hover {
    color: white;
    &:before {
      transform: translateX(0%);
    }
  }
  &:disabled {
    opacity: 0.5;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25), inset 0 2px 4px 0 var(--white);
  }
`;
