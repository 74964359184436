export const vacancies = [
    {
        id: '0',
        title: 'Junior Frontend Developer',
        tags: [
            {
                'title': 'HTML',
                'style': 'pink'
            },
            {
                'title': 'SASS',
                'style': 'blue'
            },
            {
                'title': 'LESS',
                'style': 'green'
            },
            {
                'title': 'JS',
                'style': 'orange'
            },
            {
                'title': 'React',
                'style': 'pink'
            },
            {
                'title': 'Gulp',
                'style': 'pink'
            },
            {
                'title': 'Webpack',
                'style': 'pink'
            },
            {
                'title': 'Git',
                'style': 'pink'
            },
        ],
        style: 'blue',
        html: `
            <h3><strong>Требования:</strong></h3>
            <ul>
                <li>-   Опыт коммерческой Back-end разработки от 1 года;</li>
                <li>-   Знание следующих технологий: PHP, Javascript, MySQL;</li>
                <li>-   Отличные знания OOP и MVC;</li>
                <li>-   Понимание принципов проектирования REST-сервисов;</li>
                <li>-   Опыты работы с GIt и базовые навыки работы в Linux консоли;</li>
                <li>-   Опыт написания unit-тестов;</li>
                <li>-   Опыт работы с MySQL/MongoDB/PostgreSQL.</li>
            </ul>
            <h3><strong>Обязанности:</strong></h3>
            <ul>
                <li>-   Разработка новых проектов;</li>
                <li>-   Поддержка существующих проектов;</li>
                <li>-   Код-ревью;</li>
                <li>-   Опыт написания unit-тестов;</li>
            </ul>
        `,
    },
    {
        id: '1',
        title: 'Middle Backend Developer',
        tags: [
            {
                'title': 'PHP',
                'style': 'pink'
            },
            {
                'title': 'MySQL',
                'style': 'pink'
            },
            {
                'title': 'Laravel',
                'style': 'pink'
            },
            {
                'title': 'REST',
                'style': 'pink'
            },
            {
                'title': 'PostgreSQL',
                'style': 'pink'
            },
            {
                'title': 'MongoDB',
                'style': 'pink'
            },
            {
                'title': 'Git',
                'style': 'pink'
            },
        ],
        style: 'pink',
        html: `
            <h3><strong>Требования:</strong></h3>
            <ul>
                <li>-   Опыт коммерческой Back-end разработки от 1 года;</li>
                <li>-   Знание следующих технологий: PHP, Javascript, MySQL;</li>
                <li>-   Отличные знания OOP и MVC;</li>
                <li>-   Понимание принципов проектирования REST-сервисов;</li>
                <li>-   Опыты работы с GIt и базовые навыки работы в Linux консоли;</li>
                <li>-   Опыт написания unit-тестов;</li>
                <li>-   Опыт работы с MySQL/MongoDB/PostgreSQL.</li>
            </ul>
            <h3><strong>Обязанности:</strong></h3>
            <ul>
                <li>-   Разработка новых проектов;</li>
                <li>-   Поддержка существующих проектов;</li>
                <li>-   Код-ревью;</li>
                <li>-   Опыт написания unit-тестов;</li>
            </ul>
        `,
    },
    {
        id: '2',
        title: 'Sales Manager',
        tags: [
            {
                'title': 'English',
                'style': 'pink'
            },
            {
                'title': 'Переговоры',
                'style': 'pink'
            },
            {
                'title': 'CRM',
                'style': 'pink'
            },
            {
                'title': 'Поиск клиентов',
                'style': 'pink'
            },
            {
                'title': 'Переписка',
                'style': 'pink'
            },
        ],
        style: 'green',
        html: `
            <h3><strong>Требования:</strong></h3>
            <ul>
                <li>-   Опыт коммерческой Back-end разработки от 1 года;</li>
                <li>-   Знание следующих технологий: PHP, Javascript, MySQL;</li>
                <li>-   Отличные знания OOP и MVC;</li>
                <li>-   Понимание принципов проектирования REST-сервисов;</li>
                <li>-   Опыты работы с GIt и базовые навыки работы в Linux консоли;</li>
                <li>-   Опыт написания unit-тестов;</li>
                <li>-   Опыт работы с MySQL/MongoDB/PostgreSQL.</li>
            </ul>
            <h3><strong>Обязанности:</strong></h3>
            <ul>
                <li>-   Разработка новых проектов;</li>
                <li>-   Поддержка существующих проектов;</li>
                <li>-   Код-ревью;</li>
                <li>-   Опыт написания unit-тестов;</li>
            </ul>
        `,
    },
];