import React from 'react';
import { isMobile } from 'react-device-detect';
import styled, { keyframes } from 'styled-components/macro';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import down from './img/down.svg';

const float = keyframes`
  0% {
    transform: translateY(-10%);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10%);
  }
`;

const Down = styled(Link)`
  position: absolute;
  z-index: 5;
  bottom: ${rem('24px')};
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: ${({ phase }) => {
    switch (phase) {
      case 'entered':
        return 'translate(-50%, 0)';
      case 'exiting':
        return 'translate(-50%, 100%)';
      default:
        return 'translate(-50%, 100%)';
    }
  }};
  opacity: ${({ phase }) => {
    switch (phase) {
      case 'entered':
        return 1;
      case 'exiting':
        return 0;
      default:
        return 0;
    }
  }};
  transition: all 0.3s ease;

  span {
    color: #fff;
    font-size: ${rem('16px')};
    font-weight: 400;
    letter-spacing: 1.6px;
  }

  img {
    height: ${rem('28px')};
    margin: 8px 0 0;
    animation: ${float} 1s ease infinite;
  }

  @media (max-width: 767px) {
    bottom: ${rem('12px')};
  }
`;

export const ScrollDown = ({ isScrollMode, ...props }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Down {...props}>
        <span>{t(isMobile || !isScrollMode ? 'Proceed' : 'Scroll down')}</span>
        <img src={down} alt="" />
      </Down>
    </div>
  );
};
