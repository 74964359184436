function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context('./img/services', false, /\.(png|jpe?g|svg)$/)
);

export const services = [
  {
    id: '0',
    title: 'Разработка сайтов и web-приложений',
    style: 'pink',

    image: images[0],
    text: [
      {
        p:
          'Настройка обмена данными между сайтом и 1С, Bitrix24, Dynamics 365, Iiko, R-keeper, Мой склад, RetailCRM, RetailRocket и т.д',
      },
      {
        p: 'Спецпроекты',
      },
      {
        p: 'Медиаресурсы',
      },
      {
        p: 'Интернет-магазины',
      },
      {
        p: 'Информационные Порталы',
      },
      {
        p: 'Биржи',
      },
      {
        p: 'Веб-сервисы',
      },
    ],
  },
  {
    id: '1',
    title: 'Интеграция сайтов с CRM, ERP',
    style: 'blue',
    image: images[1],
    text: [
      {
        p:
          'Настройка обмена данными между сайтом и 1С, Bitrix24, Dynamics 365, Iiko, R-keeper, Мой склад, RetailCRM, RetailRocket и т.д',
      },
      {
        p: 'Поключение модулей доставки, эквайринга, ЭДО ',
      },
    ],
  },
  {
    id: '003',
    title: 'Техподдержка и мониторинг',
    style: 'green',
    image: images[2],
    text: [
      {
        p:
          'Мониторинг стабильности работы сайта, своевременное уведомление о сбоях',
      },
      {
        p: 'Бекапы и восстановление',
      },
      {
        p: 'Лечение от вирусов и экстренная помощь ',
      },
    ],
  },
  {
    id: '004',
    title: 'Сложные и нетиповые решения',
    style: 'purple',
    image: images[3],
    text: [
      {
        p: 'Калькуляторы, конструкторы, MVP для стартапов',
      },
      {
        p: 'Написание  ТЗ',
      },
      {
        p: 'Разработка архитектуры',
      },
    ],
  },
  {
    id: '005',
    title: 'Сбор и анализ данных',
    style: 'orange',
    image: images[4],
    text: [
      {
        p: 'Разработка парсеров любой сложности и назначения',
      },
      {
        p: 'Выгрузка в товарные агрегаторы и маркетплейсы',
      },
      {
        p: 'Подключение и настройка  метрики, аналитики, мониторинга ',
      },
    ],
  },
];
