import React, { useState } from 'react';
import axios from 'axios';
import swal from '@sweetalert/with-react';
import { useForm } from 'react-hook-form';
import { Button } from 'components';

import ReCAPTCHA from 'react-google-recaptcha';
import './style.scss';

export const ContactForm = (props) => {
  const { handleSubmit, register, errors } = useForm();
  const [captcha, setCaptcha] = useState('');
  function onChangeCaptcha(value) {
    setCaptcha(value);
    console.log(value);
  }
  const onSubmit = (values) => {
    axios
      .post(`${process.env.REACT_APP_API}/contacts`, values)
      .then(() => {
        swal({
          title: 'Спасибо',
          text: 'Ваше сообщение успешно отправлено',
          icon: 'success',
        });
      })
      .catch(() => {
        swal({
          title: 'Извините',
          text: 'Произошла ошибка',
          icon: 'error',
        });
      });
  };

  return (
    <form
      className="contact-form"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="contact-form__row">
        <input
          className="contact-form__input"
          name="name"
          ref={register({
            required: 'Required',
          })}
          isError={errors.name}
          placeholder="Ваше имя"
        />
      </div>

      <div className="contact-form__row">
        <input
          className="contact-form__input"
          name="phone"
          ref={register({
            required: 'Required',
            pattern: {
              value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/gm,
              message: 'Invalid phone address',
            }
          })}
          isError={errors.phone}
          placeholder="Номер телефона для связи"
        />
      </div>
      <div className="contact-form__row">
        <input
          className="contact-form__input"
          name="email"
          ref={register({
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
          })}
          isError={errors.email}
          placeholder="E-mail"
        />
      </div>
      <div className="contact-form__row">
        <textarea
          className="contact-form__textarea"
          name="text"
          ref={register({
            required: 'Required',
          })}
          isError={errors.text}
          placeholder="Сообщение"
        />
      </div>
      <div className="contact-form__row">
        <input
          className="contact-form__input"
          name="link"
          ref={register({
            required: 'Required'
          })}
          isError={errors.link}
          placeholder="Прикрепить ссылку"
        />
      </div>
      <div className="contact-form__captcha">
        <ReCAPTCHA
          sitekey="6LcUmKwZAAAAAPv7sDahOU3XwFj5XjAOAokJUWX7"
          onChange={onChangeCaptcha}
        />
      </div>
      <Button type="submit" disabled={!captcha}>
        <span>Отправить</span>
      </Button>
    </form>
  );
};
