import React, { useState, useEffect, Suspense, useRef } from 'react';
import styled from 'styled-components/macro';
import { Route, useLocation, Switch, useHistory } from 'react-router-dom';
import {
  Transition,
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import { NavLink } from 'react-router-dom';

import { WheelContext } from 'contexts';
import { Menu, Burger, Loading } from 'components';
import { ReactComponent as HeaderLogo } from './img/header-logo.svg';
import { routes } from 'routes';
// import { theme } from 'theme';
import './style.scss';

const Wrapper = styled.section`
  height: 100%;
  user-select: none;
`;

const Content = styled.main`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding: 0;
  overflow: hidden;
`;

// const routes = [
//   { path: '/', Component: Main },
//   { path: '/about', Component: About },
//   { path: '/contacts', Component: Contacts },
//   { path: '/portfolio', Component: Portfolio },
//   {
//     path: '/portfolio/:slug',
//     Component: PortfolioDetail,
//   },
//   { path: '/services', Component: Services },
//   { path: '/vacancies', Component: Vacancies },
// ];

// const scrollRoutes = ['/', '/about', '/services'];

const Screens = () => {
  const lastWheelTime = useRef(0);
  const pageRef = useRef();
  const [nextPage, setNextPage] = useState('/');
  const [isScrollMode, setIsScrollMode] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [isShowScroll, setIsShowScroll] = useState(false);
  const location = useLocation();
  const burgerRef = useRef();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // visRef.current(burgerRef.current, theme.onMouseDown);
  };

  const bottomNavRef = useRef({ animate: true });

  useEffect(() => {
    bottomNavRef.current.animate = false;
  });

  // useEffect(() => {
  //   setIsShowScroll(scrollRoutes.includes(location.pathname));
  // }, [location]);

  return (
    <WheelContext.Provider
      value={{
        lastWheelTime,
        setNextPage,
        setIsScrollMode,
      }}
    >
      <Wrapper>
        <Content>
          <TransitionGroup>
            <CSSTransition
              // in={Boolean(match)}
              timeout={300}
              // unmountOnExit
              key={location.pathname}
              classNames="page"
            >
              <Switch location={location}>
                {routes.map(({ path, Component }) => (
                  <Route key={path} path={path} exact={true}>
                    <Component ref={pageRef} bottomNavRef={bottomNavRef} />
                  </Route>
                ))}
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </Content>

        {location.pathname !== '/' ? (
          <div className="header">
            <div className="header__logo">
              <NavLink className="header__link" to="/">
                <HeaderLogo />
              </NavLink>
            </div>
          </div>
        ) : (
          false
        )}

        {location.pathname !== '/' ? (
          <Burger isActive={isMenuOpen} onClick={toggleMenu} ref={burgerRef} />
        ) : (
          false
        )}

        <Transition
          in={isMenuOpen}
          mountOnEnter
          unmountOnExit
          timeout={{ exit: 300 }}
        >
          {(phase) => <Menu phase={phase} toggleMenu={toggleMenu} />}
        </Transition>
      </Wrapper>
    </WheelContext.Provider>
  );
};

export const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Screens />
    </Suspense>
  );
};
