import React from 'react';
import './style.scss';

import { technologies } from '__mocks__/technologies';

export const Tech = () => {
  return (
    <div className="tech">
      {technologies.map((item, index) => (
        <div key={item.title} className="tech__section">
          <div className="tech__section-title">{item.title}</div>
          <div className="tech__list">
            {item.items.map((item, index) => (
              <div key={item.id} className="tech__list-item">
                <img src={`${item.image}`} alt={item.title} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
