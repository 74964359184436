import React, { useState, forwardRef, useContext, useEffect } from 'react';

import { Nav } from './nav';
import { Page } from 'components';
import { Redirect, useParams } from 'react-router-dom';
import { useApi } from 'hooks';
import './style.scss';

import { projects } from '__mocks__/portfolio';

export const PortfolioDetail = forwardRef((props, forwardedRef) => {
  const { slug } = useParams();
  const endpoint = 'projects/' + slug;
  const [{ done, isLoading, isError, data }, setUrl] = useApi(endpoint, []);

  // const done = true,
  //   isError = false;

  // console.log(data);
  const prevUrl = data?.prev && '/portfolio/' + data.prev;
  const nextUrl = data?.next && '/portfolio/' + data.next;
  const project = data;

  if (isError) {
    return <Redirect to={'/'} />;
  } else {
    return (
      <Page
        ref={forwardedRef}
        className="page page_portfolio-detail"
        {...props}
      >
        {done ? (
          <div className="portfolio-detail">
            <Nav
              nextUrl={nextUrl}
              prevUrl={prevUrl}
              className="portfolio-detail__nav_show-mob"
            />
            <div className="portfolio-detail__left">
              <img
                className="portfolio-detail__logo"
                src={project['attachOne|preview_image']?.path}
                alt=""
              />
              <p
                className={`portfolio-detail__description${
                  project.link ? ' portfolio-detail__description_border' : ''
                }`}
              >
                {project.preview_text}
              </p>

              {project.link ? (
                <a
                  className="portfolio-detail__project-link"
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {project.link_text || project.link}
                </a>
              ) : (
                false
              )}
              <p className="portfolio-detail__features-title">Что сделали:</p>

              <div
                className="portfolio-detail__features"
                dangerouslySetInnerHTML={{ __html: project.description }}
              ></div>

              <p className="portfolio-detail__features-title">Технологии:</p>

              <ul className="portfolio-detail__tags">
                {project.tags?.split(',').map((tag, i) => (
                  <li key={i}>{tag}</li>
                ))}
              </ul>
            </div>
            <div className="portfolio-detail__right">
              <div className="portfolio-detail__right-scroll">
                <Nav
                  nextUrl={nextUrl}
                  prevUrl={prevUrl}
                  className="portfolio-detail__nav_hide-mob"
                />
                <div className="portfolio-detail__images">
                  {project['attachMany|images'].map((img, i) => (
                    <img
                      src={img.path}
                      key={i}
                      className="portfolio-detail__image"
                      alt=""
                    />
                  ))}
                </div>
                <Nav nextUrl={nextUrl} prevUrl={prevUrl} />
              </div>
            </div>
          </div>
        ) : (
          <div className="preloader portfolio-detail__preloader"></div>
        )}
      </Page>
    );
  }
});
