import React, { forwardRef } from 'react';
import { ContactForm, ScrollPage as Page, Title } from 'components';
import './style.scss';

export const Contacts = forwardRef((props, forwardedRef) => {
  return (
    <Page className="page" ref={forwardedRef} toUp="/about" {...props}>
      <div className="contacts-page">
        <Title>Хотите обсудить проект?</Title>
        <ContactForm />
      </div>
    </Page>
  );
});
