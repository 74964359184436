import React from 'react';
import { Nav } from '../Nav/Nav';
import { Footer } from '../Footer/Footer';
import classNames from 'classnames';
import './style.scss';

export const Menu = ({ toggleMenu, ...props }) => {
  const { phase } = props;
  return (
    <div className={classNames('drawer', phase)} {...props}>
      <div className="drawer__content">
        <div className="drawer__container">
          <Nav className="drawer__col" phase={phase} clickAction={toggleMenu} />
          <Footer clickAction={toggleMenu} />
        </div>
      </div>
    </div>
  );
};
