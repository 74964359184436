import React from 'react';
import {
  MarqueeSt,
  MarqueeListSt,
  MarqueeListItemSt,
  MarqueeImgSt,
} from './styles';

export const Marquee = ({ items, ...props }) => {
  return (
    <MarqueeSt>
      <MarqueeListSt {...props}>
        {items.map((item, i) => (
          <MarqueeListItemSt key={i}>
            <MarqueeImgSt src={item.img} alt="" />
          </MarqueeListItemSt>
        ))}
      </MarqueeListSt>
      <MarqueeListSt {...props}>
        {items.map((item, i) => (
          <MarqueeListItemSt key={i}>
            <MarqueeImgSt src={item.img} alt="" />
          </MarqueeListItemSt>
        ))}
      </MarqueeListSt>
    </MarqueeSt>
  );
};
