import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as IconVk } from './img/vk-icon.svg';
import { ReactComponent as IconIn } from './img/in-icon.svg';
import { ReactComponent as IconHh } from './img/hh-icon.svg';
import { ReactComponent as IconCms } from './img/cms-icon.svg';
import { ReactComponent as IconGit } from './img/git-icon.svg';

import './style.scss';

export const Footer = ({ clickAction }) => {
  return (
    <footer className="footer">
      {/* <div className="job">
        <h4 className="footer__title">Работа у нас</h4>
        <ul className="footer-menu">
          <li className="footer-menu__item" onClick={clickAction}>
            <NavLink className="footer-menu__link" to="/vacancies">
              Вакансии
            </NavLink>
          </li>
          <li className="footer-menu__item">
            <a
              className="footer-menu__link"
              href="https://vk.com/@rubium-pro-stazhirovku-faq"
              target="_blank"
              rel="noopener noreferrer"
            >
              Стажировка
            </a>
          </li>
        </ul>
      </div> */}
      <div className="contacts">
        <h4 className="footer__title">Контакты</h4>
        <div className="contacts__address">
          <p>г. Таганрог, Гоголевский, 27, оф. 27</p>
          <p>8-928-322-223</p>
          <p>hello@rubium.ru</p>
        </div>
      </div>
      <div className="footer__social">
        <ul className="social-links">
          <li className="social-links__item">
            <a
              href="https://vk.com/rubium"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconVk />
            </a>
          </li>
          <li className="social-links__item">
            <a
              href="https://taganrog.hh.ru/employer/4585130"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconHh />
            </a>
          </li>
          <li className="social-links__item">
            <a
              href="https://www.linkedin.com/company/rubium-web"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconIn />
            </a>
          </li>
          <li className="social-links__item">
            <a
              title="Участник проекта CMS Magazine"
              href="//cmsmagazine.ru/creators/rubium/?_type_jump=by_our_banner"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconCms />
            </a>
          </li>
          <li className="social-links__item">
            <a
              title="Участник проекта CMS Magazine"
              href="https://github.com/rubium-web"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconGit />
            </a>
          </li>
        </ul>
      </div>
      {/* <div className="footer__actions">
        <NavLink
          className="footer-menu__link"
          to="/contacts"
          onClick={clickAction}
        >
          Обсудить проект
        </NavLink>
      </div> */}
    </footer>
  );
};
