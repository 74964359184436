import React, { useState, forwardRef } from 'react';
import { useApi } from 'hooks';
import classNames from 'classnames';
import { ScrollPage, Title } from 'components';
import { services } from '__mocks__/services';
import './style.scss';

export const Services = forwardRef((props, forwardedRef) => {
  const [activeId, setActiveId] = useState(0);
  const activeService = services[activeId];
  const selectActive = (i) => {
    setActiveId(i);
  };
  return (
    <ScrollPage ref={forwardedRef} toUp="/" toDown="/portfolio" {...props}>
      <div className="services-page">
        <div className="page__container services-page__container">
          <Title>Услуги</Title>

          <div className="services-page__wrapper">
            <div className="services-menu">
              {services.map((service, i) => (
                <div
                  key={`service-${i}`}
                  // item={service}
                  className={classNames(
                    'services-menu__item',
                    {
                      'active': activeId === i,
                    },
                    service.style
                  )}
                  onClick={() => selectActive(i)}
                >
                  {service.title}
                </div>
              ))}
            </div>
            <div className="service">
              <ul className="service__content">
                {activeService.text.map((item, i) => (
                  <li key={i}> {item.p}</li>
                ))}
              </ul>
              <div className="service__image service-img">
                <img src={activeService.image} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScrollPage>
  );
});
