import React from 'react';
import classNames from 'classnames';
import './style.scss';

export const Burger = React.forwardRef((props, ref) => {
  return (
    <div
      className={classNames('menu-button', { 'opened': props.isActive })}
      onClick={props.onClick}
      ref={ref}
    >
      <i className="menu-button__line"></i>
      <i className="menu-button__line"></i>
      <i className="menu-button__line"></i>
    </div>
  );
});
