import React from 'react';
import { NavLink } from 'react-router-dom';

export function Nav({ nextUrl, prevUrl, className, ...props }) {
  return (
    <div className={`portfolio-detail__nav ${className || ''}`}>
      <NavLink
        to={'/portfolio'}
        className="portfolio-detail__nav-back portfolio-detail__arrow"
      >
        К проектам
      </NavLink>
      {prevUrl ? (
        <NavLink
          to={prevUrl}
          className="portfolio-detail__nav-prev portfolio-detail__arrow"
        />
      ) : (
        <span className="portfolio-detail__nav-prev portfolio-detail__arrow portfolio-detail__disabled"></span>
      )}
      {nextUrl ? (
        <NavLink
          to={nextUrl}
          className="portfolio-detail__nav-next portfolio-detail__arrow"
        />
      ) : (
        <span className="portfolio-detail__nav-next portfolio-detail__arrow portfolio-detail__disabled"></span>
      )}
    </div>
  );
}
