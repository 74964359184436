import styled from 'styled-components/macro';

// TODO: animation
export const Loading = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  transform: translate(-50%, -50%);
  border: 3px solid #fff;
  border-radius: 50%;
`;
