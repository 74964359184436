function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context('./img/projects', false, /\.(png|jpe?g|svg)$/)
);

export const projects = [
  {
    id: '001',
    title: 'Finliners',
    image: images[3],
    images: importAll(
      require.context('./img/finliners', false, /\.(png|jpe?g|svg)$/)
    ),
    'tags': [
      {
        'title': 'go',
        'style': 'green',
      },
      {
        'title': 'python',
        'style': 'purple',
      },
      {
        'title': 'clickhouse',
        'style': 'blue',
      },
      {
        'title': 'backend',
        'style': 'green',
      },
      {
        'title': 'frontend',
        'style': 'blue',
      },
      {
        'title': 'Портал',
        'style': 'purple',
      },
    ],
    'description': 'Сайт о криптовалюте и  криптобиржах',
    'link': {
      'text': '',
      'href': 'https://finliners.com/',
    },
    'features': [
      'Агрегация и динамический  вывод  данных из 8 бирж криптовалют',
      'Тематический блог',
    ],
    'date': '2019',
  },
  {
    id: '002',
    title: 'A-МЕТАЛ',
    images: importAll(
      require.context('./img/a-metal', false, /\.(png|jpe?g|svg)$/)
    ),
    image: images[2],
    tags: [
      {
        'title': 'bitrix',
        'style': 'green',
      },
      {
        'title': 'frontend',
        'style': 'blue',
      },
      {
        'title': 'backend',
        'style': 'green',
      },
      {
        'title': 'медиаиздание',
        'style': 'blue',
      },
      {
        'title': 'новостной портал',
        'style': 'purple',
      },
      {
        'title': 'Ajax',
        'style': 'purple',
      },
      {
        'title': 'Webpack',
        'style': 'purple',
      },
      {
        'title': 'Babel',
        'style': 'purple',
      },
      {
        'title': 'Eslint',
        'style': 'purple',
      },
      {
        'title': 'Scss',
        'style': 'purple',
      },
      {
        'title': 'Autoprefixer',
        'style': 'purple',
      },
      {
        'title': 'Imgproxy',
        'style': 'purple',
      },
      {
        'title': 'Sphinxsearch',
        'style': 'purple',
      },
      {
        'title': 'Supervisor',
        'style': 'purple',
      },
      {
        'title': 'Logstash',
        'style': 'purple',
      },
    ],
    'description':
      'Интернет-магазин  металлообрабатывающих станков, комплектующих и оснастки',
    'features': [
      'Интернет-магазин с номенклатурой  100 000+ наименований',
      'Конфигуратор: сборка оборудования из доступных компонентов, со сложной логикой взаимоисключений обязательных/недоступных компонентов в зависимости от выбранных и расчетом итоговой стоимости',
      'Заявки на тех.обслуживание оборудования: подбор комплектующих, напоминания о закупках, сведения о проведенных работах по тех.обслуживанию',
      'Бонусная система: промокоды, скидки по бонусному уровню, скидки по категориям/другим критериям, расчет стоимости доставки',
      'Фильтр в каталоге: сео-наборы фильтров (страницы для поиска с активированным фильтром), фильтрация в каталоге по 100 параметрам (в среднем, всего 3500 опций), управление отображением опций фильтра ',
      'Регулярный обмен данным об остатках, заказах, клиентах, заявах  с 1С и совсбвенной внутренней CRM заказчика ',
    ],
    'date': '2016',
  },
  // {
  //   id: '002',
  //   title: 'Digger',
  //   image: images[0],
  //   tags: [
  //     {
  //       'title': 'October CMS',
  //       'style': 'green',
  //     },
  //     {
  //       'title': 'frontend',
  //       'style': 'blue',
  //     },
  //     {
  //       'title': 'backend',
  //       'style': 'green',
  //     },
  //     {
  //       'title': 'медиаиздание',
  //       'style': 'blue',
  //     },
  //     {
  //       'title': 'новостной портал',
  //       'style': 'purple',
  //     },
  //   ],
  //   'description':
  //     'Портал о продукции компании Apple - новости, обзоры, статьи',
  //   'link': {
  //     'text': 'На сайт',
  //     'href': 'https://www.digger.ru/',
  //   },
  //   'date': '2016',
  // },
  {
    id: '003',
    title: 'Yandex maps plugin',
    image: images[7],
    'tags': [
      {
        'title': 'October CMS',
        'style': 'green',
      },
      {
        'title': 'PHP',
        'style': 'blue',
      },
      {
        'title': 'Laravel',
        'style': 'blue',
      },
      {
        'title': 'backend',
        'style': 'green',
      },
      {
        'title': 'Yandex API',
        'style': 'blue',
      },
    ],
    'description': 'Плагин Yandex-карт для October CMS',
    'link': {
      'text': 'На маркетплейс',
      'href': 'https://octobercms.com/plugin/rubium-yamapswidgets',
    },
    'date': '2018',
  },
  {
    id: '004',
    title: 'Qood',
    image: images[6],
    'tags': [
      {
        'title': 'October CMS',
        'style': 'green',
      },
      {
        'title': 'frontend',
        'style': 'blue',
      },
      {
        'title': 'backend',
        'style': 'green',
      },
      {
        'title': 'новостной портал',
        'style': 'purple',
      },
    ],
    'description':
      'Корпоративный сайт компании Qood, специализирующейся на оказани юридических, бухгалтерских и консалтинговых услуг',
    'link': {
      'text': 'На сайт',
      'href': 'https://qood.ru/',
    },
    'date': '2019',
  },
  {
    id: '006',
    title: 'MD Lavka',
    image: images[5],
    'tags': [
      {
        'title': 'October CMS',
        'style': 'green',
      },
      {
        'title': 'laravel',
        'style': 'purple',
      },
      {
        'title': 'frontend',
        'style': 'blue',
      },
      {
        'title': 'backend',
        'style': 'green',
      },
      {
        'title': 'медиаиздание',
        'style': 'blue',
      },
      {
        'title': 'новостной портал',
        'style': 'purple',
      },
    ],
    'description': 'Каталог продукции компании Apple',
    'link': {
      'text': 'На сайт',
      'href': 'https://mdlavka.ru/',
    },
    'date': '2019',
  },
  {
    id: '007',
    title: 'Пансионат Hapa',
    image: images[1],
    'tags': [
      {
        'title': 'October CMS',
        'style': 'green',
      },
      {
        'title': 'laravel',
        'style': 'purple',
      },
      {
        'title': 'frontend',
        'style': 'blue',
      },
      {
        'title': 'backend',
        'style': 'green',
      },
      {
        'title': 'медиаиздание',
        'style': 'blue',
      },
      {
        'title': 'новостной портал',
        'style': 'purple',
      },
    ],
    'description': 'Каталог продукции компании Apple',
    'link': {
      'text': 'На сайт',
      'href': 'https://mdlavka.ru/',
    },
    'date': '2019',
  },
];
