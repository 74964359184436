import styled, { keyframes, css } from 'styled-components';

const enterPauseExit = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(-100% - var(--gap)));
  }
`;

export const MarqueeSt = styled.div`
  --gap: 1rem;
  position: relative;
  display: grid;
  // overflow: hidden;
  user-select: none;
  gap: var(--gap);
`;

export const MarqueeListSt = styled.ul`
  flex-shrink: 0;
  display: grid;
  gap: var(--gap);
  min-width: 100%;
  animation: ${enterPauseExit} ${(props) => props.duration || '5s'} linear
    infinite;
  animation-direction: ${(props) => (props.reverse ? 'reverse' : 'normal')};
`;

export const MarqueeImgSt = styled.img`
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
`;

export const MarqueeListItemSt = styled.li``;
