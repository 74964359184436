import React, { useState, forwardRef } from 'react';
import classNames from 'classnames';

export const Tags = (props) => {
    if (props.tags) {
        return (
            <ul className={`tag-list${props.className ? ' ' + props.className : ''}`}>
                {props.tags.map((tag) => (
                    <li key={tag.title} className={classNames('tags-list__item tag', tag.style)}>{tag.title}</li>
                ))}
            </ul>
        )
    } else return null;

}